<template>
  <div class="container">
    <div class="content">
      <div class="login_top">
        <div class='login_top_left'>{{loginType == 3 ? $t('login.enterprise'): $t('login.self')}}</div>
        <div class='login_top_right' @click='changeLogin'>
          {{loginType == 3 ? $t('login.self'): $t('login.enterprise')}}</div>
      </div>
      <div class="login_filed">
        <!-- 企业登录 -->
        <van-cell-group v-if="loginType == 3">
          <van-field v-model="loginForm.userName" @input="emailInputHandle" clearable :placeholder="$t('login.emailAccount')" />
          <van-field v-model="loginForm.password"  :type="type" clearable :placeholder="$t('login.inputPwsd')">
            <my-icon fontSize="32" slot='right-icon' :iconName="icon" @click='showPwd'></my-icon>
          </van-field>
        </van-cell-group>
        <!-- 个人——密码登录 -->
        <van-cell-group>
          <van-field v-if="loginType == 1" v-model="loginForm.userName" clearable  @input="mobileInputHandle" :placeholder="$t('login.phoneNumber')" />
          <van-field v-if="loginType == 1" :type="type" clearable v-model="loginForm.password"
            :placeholder="$t('login.inputPwsd')">
            <my-icon fontSize="32" slot='right-icon' :iconName="icon" @click='showPwd'></my-icon>
          </van-field>
          <!-- 个人——验证码登录 -->
          <van-field v-if="loginType == 2" v-model="loginForm.userName" clearable  @input="codeInputHandle" :placeholder="$t('login.phoneNumber')" />
          <van-field v-if="loginType == 2" v-model="loginForm.verifyCode" @input="codeOnlyNumber" minlength='4' maxlength='6'
          :placeholder="$t('login.inputCode')" use-button-slot>
          <van-button slot="button" :class="codeDisabled ? 'disabled_style' : 'btn_style'" type="primary"
            @click="sendcodeHandle" :disabled="disabledCode">
            {{btnText}}
          </van-button>
        </van-field>
        </van-cell-group>
      </div>
      <van-button class="login_bn" :disabled="isDisableLoginBtn()" type="primary" @click="handleLogin">
        {{$t('home.signIn')}}</van-button>
      <Back :type='goType'></Back>
      <div v-if="loginType !== 3">
        <div class="login_info">
          <div @click='clickType'>{{loginType == 1 ? $t('login.mobilePhone'): $t('login.pws')}}</div>
          <div v-if="loginType == 1" @click='getPwd'>{{$t('login.findPwsd')}}</div>
        </div>
        <div class="login_btm">
          {{$t('login.noAccount')}}<span @click='goRegister'>{{$t('login.register')}}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import Back from "@/views/login/back.vue";
  import {
    sendCode
  } from "@/api/meet"
  import { setMobile, getPhone, setEmail, getMobile, setPhone, getEmail} from "@/utils/auth";
  import {
    checkMobile,
    checkCode,
    checkEmail
  } from "@/utils/check";
  export default {
    components: {
      Back,
    },
    data() {
      return {
        goType:'index',
        loginForm: {
          userName: '',
          password: '',
          verifyCode: '',
        },
        type: 'password',
        phoneNoOrEmail: '',
        icon: 'icon-mima_bukedu_48_hei',
        loginType: 3, //默认企业邮箱登录
        btnText: this.$t('login.getCode'),
        timer: null,
        count: 60,
        codeDisabled:false,
        noSend:true,
      }
    },
    watch: {
    loginType: {
      handler(newVal) {
        if (newVal === 3) {
          this.loginForm.userName = getEmail() || ''// 企业登录
        } else if(newVal === 1){
          this.loginForm.userName = getMobile() || getPhone()//个人手机号登录
        }else if(newVal === 2) {
          this.loginForm.userName = getPhone() || getMobile()//个人验证码登录
        }
      },
      immediate: true
    }
  },
  computed:{
    disabledCode(){
        return !checkMobile(this.loginForm.userName)
      },
    },
    methods: {
      codeOnlyNumber(value) {
        this.loginForm.verifyCode = value.replace(/[^\d]/g, "");
      },
        // 验证码倒计时
        getCode() {
        const TIME_COUNT = 60;
        if (!this.timer) {
          this.count = TIME_COUNT;
          this.codeDisabled = true;
          this.noSend = false;
          this.timer = setInterval(() => {
            if (this.count > 0 && this.count <= TIME_COUNT) {
              this.count = this.count - 1;
              this.btnText = this.count + this.$t('login.resend');
            } else {
              this.codeDisabled = false;
              this.noSend = true;
              clearInterval(this.timer);
              this.btnText = this.$t('login.getCode');
              this.timer = null;
            }
          }, 1000);
        }
      },
       sendcodeHandle() {
        if (!checkMobile(this.loginForm.userName)) {
          this.$toast(this.$t('login.try'))
          return
        }
        if(!this.noSend) return
        sdk.auth.getVerifyCode(this.loginForm.userName)
      .then(res=> {
        this.$toast('发送成功');
        this.getCode();
      })
      .catch(error=>{
        this.$eventBus.$emit('network-error', error)
      })
      },
      // 清除倒计时
      destroyed() {
        if (this.timer) {
          clearInterval(this.timer);
        }
      },
      isDisableLoginBtn() {
        if (this.loginType == 3) {
          //企业登录
          return !(this.loginForm.userName && this.loginForm.password)
        } else {
          if (this.loginType == 2) {
             //个人——验证码登录
             return !(this.loginForm.userName &&this.loginForm.verifyCode)

          } else { //个人——密码登录
            return !(this.loginForm.userName && this.loginForm.password)
          }
        }
      },
      emailInputHandle(e){
        setEmail(e)
      },
      mobileInputHandle(e){
        setMobile(e)
      },
      codeInputHandle(e){
        setPhone(e)
      },
      showPwd() {
        if (this.type === 'test') {
          this.type = 'password'
          this.icon = 'icon-mima_bukedu_48_hei'
        } else {
          this.type = 'test'
          this.icon = 'icon-mima_kedu_48_hei'
        }
      },
      async  handleLogin(){
      // loginType 1密码登录、2验证码登录、3企业登陆
          if (this.loginType === 2) {
            if (!checkCode(this.loginForm.verifyCode)) {
              this.$toast(this.$t('login.failTextCode'))
              return
            }
            sdk.auth.loginWithVerifyCode(this.loginForm.userName, this.loginForm.verifyCode)
            .then(res=> {
              this.$router.push({
                name: 'home'
              })
            })
            .catch(error=>{
              this.$eventBus.$emit('login-error', error)
            })
          }else{
            if (this.loginType === 3) {
              if (!checkEmail(this.loginForm.userName)) {
                this.$toast(this.$t('login.failEmail'))
                return
              }
            } else {
              if (!checkMobile(this.loginForm.userName)) {
                this.$toast(this.$t('login.try'))
                return
              }
            }

            sdk.auth.loginWithPassword(this.loginForm.userName, this.loginForm.password, this.loginType)
            .then(res=> {
              this.$router.push({
                name: 'home'
              })
            })
            .catch(error=>{
              this.$eventBus.$emit('login-error', error)
            })
          }
      },

      // async handleLogin() {
      //   // loginType 1密码登录、2验证码登录、3企业登陆
      //   try {
      //     if (this.loginType === 2) {
      //       if (!checkCode(this.loginForm.verifyCode)) {
      //         this.$toast(this.$t('login.failTextCode'))
      //         return
      //       }
      //       await sdk.auth.loginWithVerifyCode(this.loginForm.userName, this.loginForm.verifyCode);
      //     } else {
      //       if (this.loginType === 3) {
      //         if (!checkEmail(this.loginForm.userName)) {
      //           this.$toast(this.$t('login.failEmail'))
      //           return
      //         }
      //       } else {
      //         if (!checkMobile(this.loginForm.userName)) {
      //           this.$toast(this.$t('login.try'))
      //           return
      //         }
      //       }
      //       await sdk.auth.loginWithPassword(this.loginForm.userName, this.loginForm.password, this.loginType);
      //     }
      //     this.$router.push({
      //       name: 'home'
      //     })
      //   } catch (error) {
      //     this.$eventBus.$emit('login-error', error)
      //   }
      // },
      changeLogin() {
        this.loginType = this.loginType == 3 ? 1 : 3
        this.loginForm.password = ''
      },
      clickType() {
        this.loginType = this.loginType == 1 ? 2 : 1
      },
      getPwd() {
        this.$router.replace({
          name: 'forgetpassword'
        })
      },
      goRegister() {
        this.$router.replace({
          name: 'register'
        })
      },
    }
  }

</script>
<style lang="less" scoped>
  /* 登录部分输入框样式 */
  /deep/ .van-cell {
    padding: 10px 2px;
    margin-top: 18px;
    border-bottom: 0.1px solid #E5E5E5;

    &::after {
      right: 2px;
      left: 2px;
      border: none;
    }

    &:hover {
      border-bottom: 0.1px solid #1AB370;
    }
  }

  /deep/ .van-hairline--top-bottom::after {
    border: none;
  }

  .container {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;

    .content {
      padding: 80px 64px;
      box-sizing: border-box;

      .login_top {
        display: flex;
        justify-content: space-between;
        line-height: 64px;

        &_left {
          font-size: 48px;
          color: #333333;
        }

        &_right {
          font-size: 28px;
          color: #1AB370;
        }
      }

      .login_filed {
        margin-top: 60px;
        .public-code() {
            width: 198px;
            height: 64px;
            border-radius: 4px;
            font-size: 24px;
          }

          /deep/ .van-button--normal {
            padding: 0px;
          }

          .btn_style {
            background: rgba(255, 255, 255);
            border: 1px solid #FF8200;
            color: #FF8200;
            .public-code();
          }

          .disabled_style {
            border: none;
            background: #FFDFA3;
            color: #FFFFFF;
            .public-code();
          }
      }

      .login_info {
        display: flex;
        justify-content: space-between;
        font-size: 28px;
        color: #666666;
      }

      .login_bn {
        width: 622px;
        height: 88px;
        background: #1AB370;
        border-radius: 10px;
        border: none;
        margin: 80px 0 40px 0;
        font-size: 32px;
      }

      .login_btm {
        position: absolute;
        left: 0;
        right: 0;
        text-align: center;
        font-size: 28px;
        color: #999999;
        text-align: center;
        bottom: 80px;
        span {
          color: #1AB370;
        }
      }
    }
  }

</style>
